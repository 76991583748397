body {
  background-color: black;
  font-family: 'Oswald', sans-serif;
  font-weight: 100;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: 1em;
}

h1, h2 {
  text-transform: uppercase;
  font-family: 'Bungee', cursive;
  font-weight: normal;
}

.App {
  text-align: center;
  color: white;
  color: rgba(240,240,240,1);
}

main {
  overflow: auto;
}

.name {
  margin-top: 50vh;
  margin-left: 10px;
  animation-name: fly-in-from-right;
  animation-duration: .5s;
  animation-timing-function: ease-out;
}

@keyframes fly-in-from-right {
  0% {
    transform: translateX(100vw);
  }
}

.name h1 {
  text-align: left;
  margin: 0;
  line-height: 40px; 
  font-size: 48px;
  text-shadow: 2px 0px 2px white;
  user-select: none;
  cursor: default;
   background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, .55)); 
}

.hero {
  height: 75vh;
  width: 75vw;
  right: 0;
  top: 0;
  background: url('hero.jpg');
  background-size: cover;
  z-index: -1;
  position: absolute;
}

.hero-overlay {
  height: 100%; 
  width: 100%; 
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); 
}

.blurb {
  text-shadow: 1px 1px 1px black;
  text-align: left;
  width: 80vw;
  padding: 10px;
  margin-right: 0;
}

.mouse {
  /* display: block; */
  margin: 0 auto;
  width: 26px;
  height: 46px;
  border-radius: 13px;
  border: 2px solid #e8f380;
  /* bottom: 40px; */
  /* position: absolute; */
  /* left: 50%; */
  /* margin-left: -14px; */
}

.mouse span {
  display: block; 
  margin: 6px auto;
  width: 2px;
  height: 2px;
  border-radius: 4px;
  background: #e8f380;
  border: 1px solid transparent;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
}

.skills-tools-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18vh 0 70px;
  width: 100%;
  background-color: rgba(200,200,200,.4);
}

.skills-container,
.tools-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills-container h2,
.tools-container h2 {
  user-select: none;
  cursor: default;
}

.skills,
.tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.skill,
.tool {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 2vw;
  transition: .25s all;
}

.skill:hover,
.tool:hover {
  perspective: 500px;
  transform: scale(.9);
}

.skill span,
.tool span {
  color: rgba(30,30,30,1);
  font-weight: 400;
  user-select: none;
  cursor: default;
  text-shadow: 1px 0px 1px white, 0px 1px 1px white, -1px 0px 1px white, 0px -1px 1px white;
  opacity: 0;
  transition: .25s all;
}

.skill:hover span,
.tool:hover span {
  opacity: 1;
  transform: scale(1.3);
}

nav {
  position: fixed;
  bottom: 0;
  transition: .25s all;
  border-top: 1px solid white;
  width: 100vw;
  overflow: hidden;
  background-color: rgba(30,30,30,1);
}

nav ul {
  list-style: none;
  display: flex;
  width: 100vw;
  padding: 0;
  margin: 0;
}

nav ul li {
  width: 34vw;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
  padding: 16px 0;
}

.selected {
  background-color: white;
  color: black;
}

.popup {
  height: 100px;
  transition: .25s all;
}

.hidden {
  height: 1px;
}

.contact-info {
  background-color: rgba(30,30,30,1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  height: 100%;
  z-index: 1;
}

.contact-info .links {
  width: 100%;
}

.contact-info .links svg {
  font-size: 26px;
  margin: 5px 15px;
}

.contact-info .links a:hover svg,
.contact-info .links a:active svg {
  transform: scale(1.1);
  animation: flashing .6s linear infinite;
}

@keyframes flashing {
  50% {
    color: #e8f380;
  }
}

.project-nav {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
}

.project-nav-inner {
  display: inherit;
}

.project-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  min-width: 145px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.project-link-overlay {
  background-color: rgba(0,0,0,.7);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: .25s all;
  cursor: pointer;
}

.project-link-overlay:hover {
  opacity: 0;
  transform: translateY(25px);
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 52px;
}

.project h1 {
  font-family: 'Bungee', cursive;
  font-weight: normal;
  align-self: flex-start;
  margin: 10px;
}

.project .info {
  margin: 10px 0;
  padding: 10px;
  display: flex;
  background-color: rgba(150, 150, 150, 1);
}

.project .info-left {
  width: 60%;
}

.project .info-right {
  width: 40%;
}

.project .description,
.project .technologies,
.project .github {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

.project .description {
  text-align: justify;
  color: black;
}

.project .technologies p {
  font-size: 13px;
  margin: 5px 5px 5px 5px;
  padding: 5px;
  background-color: darkslategrey;
  color: #DDD;
  border-radius: 5px;
}

.project .links {
  justify-content: center;
  padding: 10px;
}

.project .links a {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 5px;
  padding: 5px;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid darkslategrey;
  color: darkslategrey;
  transition: .25s all;
}

.project .links a:hover {
  background-color: darkslategrey;
  color: white;
}

.screenshot-display {
  width: 100vw;
  height: 40vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.prev-screenshot, 
.next-screenshot {
  display: flex;
  align-items: center; 
  position: absolute;
  height: 100%;
  width: 40px;
  cursor: pointer;
}

.prev-screenshot {
  left: 0;
  justify-content: flex-start;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .85));
}

.next-screenshot {
  right: 0;
  justify-content: flex-end;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, .85));
}

.prev-screenshot i,
.next-screenshot i {
  padding: 10px
}

footer {
  background-color: rgba(200,200,200,.4);
  padding: 10px;
  margin-bottom: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

  #top-arrow,
  #bottom-arrow {
    position: fixed;
    text-align: center;
    width: 100px;
    bottom: 38px;
  }

  #top-arrow {
    left: -30px;
    transform: rotate(270deg);
  }

  #bottom-arrow {
    right: -30px;
    transform: rotate(90deg);
  }

  .arrow {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    animation-name: pokepoke; 
    animation-delay: .25s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    color: #e8f380;
  }

  @keyframes pokepoke {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0;
      transform: translateY(-10px);
    }
    51% {
      transform: translateY(10px);
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }

  }

@media screen and (min-width: 600px) {
  main {
    height: 100vh;
    margin: 0;
  }

  p, a {
    font-size: 22px;
  }

  .home h1 {
    font-size: 80px;
    line-height: 70px;
  }

  .name {
    animation-duration: .75s;
  }

  .skills-tools-container {
    flex-direction: row;
    align-items: flex-start;
    margin: 18vh 0px 52px;
  }

  .skills-container {
    width: 70%;
  }
  .tools-container {
    width: 30%;
  }

  .tools {
    flex-direction: column; 
    align-items: center;
  }

  .hero {
    height: 100vh;
    width: 75vw;
    bottom: 0;
    top: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  .blurb {
    font-size: 22px;
  }
  
  nav {
    top: 0;
    right: 0;
    bottom: auto;
    border: none;
    width: auto;
    height: auto;
    background-color: transparent;
  }

  nav ul {
    width: auto;
    justify-content: flex-end;
    margin: 0;
  }

  nav ul li {
    width: auto;
    padding: 29px 20px 20px;
    font-size: 18px;
    background-color: rgba(0,0,0,.7);
  }

  .popup {
    height: calc(100vh - 75px);
    display: flex; 
    align-items: flex-end;
    justify-content: center;
    opacity: 1;
  }

  .contact-info {
    background-color: rgba(0,0,0,.7); 
    width: 285px;
    padding: 10px;
    align-items: flex-end;
    align-self: flex-start;
    justify-content: flex-start;
    animation: fly-in-from-right .25s;
  }

  .contact-info .links svg {
    font-size: 42px;
    margin: 10px 15px 0px;
  }

  .project-nav {
    flex-direction: column;
    position: relative;
    padding: 10px; 
    background-color: rgba(0,0,0,.7);
    position: fixed;
    top: 75px;
    right: 0px;
    bottom: 0px;
    animation: fly-in-from-right .25s;
  }

  #top-arrow,
  #bottom-arrow {
    position: absolute;
    text-align: center;
    left: 10px;
    width: inherit;
    right: 10px;
  }

  #top-arrow {
    top: 10px;
    bottom: inherit;
    transform: rotate(0deg);
  }

  #bottom-arrow {
    bottom: 10px;
    top: inherit;
    transform: rotate(180deg);
  }

  .arrow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    animation: pokepoke 2s infinite;
  }

  @keyframes pokepoke {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0;
      transform: translateY(-10px);
    }
    51% {
      transform: translateY(10px);
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }

  }

  .project-nav-inner {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .project-link {
    width: 285px;
    min-height: 100px;
    background-color: rgba(30,30,30,1);
  }

  .project-link-overlay:hover {
    transform: translateX(0px);
  }

  .hidden {
    display: none;
  }

  .project h1 {
    font-size: 48px;
  }

  .project .info {
    width: 80vw;
  }

  .project .info-left {
    width: 70%
  }

  .project .info-right {
    width: 30%;
  }

  .project .technologies p {
    font-size: 16px;
  }

  .screenshot-display {
    width: 80vw;
    height: 60vh;
  }

  footer {
    margin-bottom: 0;
    flex-direction: row;
    justify-content: space-around;
  }
}

